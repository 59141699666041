<template>
  <section class="section-skills full-screen">
    <div class="content-wrapper v-align-wrapper">
      <div class="grid">
        <div class="row">
          <div v-for="(category, index) in data.categories" :key="index" class="column width-4">
            <h3>
              <span class="prefix">{{ index+1 }}</span>
              <span>{{ category.title }}</span>
            </h3>
            <div v-for="(skill, index) in category.skills" :key="index" class="skill">
              <h4>{{ skill.title }}</h4>
              <div class="level-wrapper">
                <div class="level" v-pagescroll="{trigger:'reached-once', animation:'width', widthPercentage: skill.level }"></div>
              </div>
              <p v-if="skill.details !== null">{{ skill.details }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionSkills',
  components: {},
  data: () => ({}),
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .section-skills{
    background-color: $layout_section-skills_background-color;

    .grid{
      .row{
        .column{
          margin: $layout_section-skills_category_gutter auto $layout_section-skills_category_gutter auto;
          max-width: $layout_section-skills_category_max-width;
          @include screens-landscape { max-width: none; }

          h3{
            position: relative;
            margin-bottom: $typo_h3_margin-bottom * 2;

            .prefix{
              position: absolute;
              left: 0;
              top: 0;
              font-size: $layout_section-skills_category-title-prefix_font-size_s;
              line-height: 1em;
              font-weight: $layout_section-skills_category-title-prefix_font-weight;
              color: $layout_section-skills_category-title-prefix_color;
              transform: translate(-28%,-38%);
              @include screens-m { font-size: $layout_section-skills_category-title-prefix_font-size_m; }
              @include screens-l { font-size: $layout_section-skills_category-title-prefix_font-size_l; }
            }
          }

          .skill{
            position: relative;
            margin-top: $layout_section-skills_category_gutter / 2;

            h4{
              padding-right: calc(#{$layout_section-skills_skill-level_width} + 10px);
            }

            .level-wrapper,
            .level-wrapper .level{
              height: $layout_section-skills_skill-level_height;
              border-radius: $layout_section-skills_skill-level_height;
            }

            .level-wrapper{
              position: absolute;
              top: 3px;
              right: 0;
              width: $layout_section-skills_skill-level_width;
              background-color: $layout_section-skills_skill-level_background-color;
              overflow: hidden;

              .level{
                width: 0%;
                top: 0;
                left: 0;
                background-size: 100%;
                background: white; /* Fallback color */
                background: $layout_colours-gradient-vivid;
                @include transition(width 750ms);
              }
            }
          }

          &:nth-of-type(1){
            @include screens-s-landscape { padding-right: $layout_sections-content-wrapper_padding_s * 0.66; }
            @include screens-m-landscape { padding-right: $layout_sections-content-wrapper_padding_m * 0.66; }
            @include screens-l { padding-right: $layout_sections-content-wrapper_padding_l * 0.66; }
          }
          &:nth-of-type(2){
            @include screens-s-landscape {
              padding-left: $layout_sections-content-wrapper_padding_s * 0.33;
              padding-right: $layout_sections-content-wrapper_padding_s * 0.33;
            }
            @include screens-m-landscape {
              padding-left: $layout_sections-content-wrapper_padding_m * 0.33;
              padding-right: $layout_sections-content-wrapper_padding_m * 0.33;
            }
            @include screens-l {
              padding-left: $layout_sections-content-wrapper_padding_l * 0.33;
              padding-right: $layout_sections-content-wrapper_padding_l * 0.33;
            }
          }
          &:nth-of-type(3){
            h3{
              .prefix{
                zoom: 0.85; /* The number "3" is specifically bigger than others with the font I am using */
              }
            }

            @include screens-s-landscape { padding-left: $layout_sections-content-wrapper_padding_s * 0.66; }
            @include screens-m-landscape { padding-left: $layout_sections-content-wrapper_padding_m * 0.66; }
            @include screens-l { padding-left: $layout_sections-content-wrapper_padding_l * 0.66; }
          }
        }
      }
    }
  }
</style>