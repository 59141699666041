<template>
  <div class="component-project-business-card">
    <div class="grid">
      <div class="row">
        <div class="column width-6" :style="{'background-color': businessCard.leftPanelBackgroundColor}">
          <div class="face-wrapper">
            <img :src="require('@/images/projects/'+businessCard.faceAImageFile)" :alt="businessCard.faceAImageAlt" />
          </div>
          <div class="face-wrapper">
            <img :src="require('@/images/projects/'+businessCard.faceBImageFile)" :alt="businessCard.faceBImageAlt" />
          </div>
        </div>
        <div class="column width-6" :style="{'background-color': businessCard.rightPanelBackgroundColor}">
          <div class="crossing-tiles">
            <div class="grid" ref="crossingTilesGrid">
              <div class="row">
                <div v-for="index in 35" :key="index" class="tile">
                  <img v-if="index === 18" :src="require('@/images/projects/'+businessCard.faceAImageFile)" :alt="businessCard.faceAImageFile" />
                  <img v-else :src="require('@/images/projects/'+businessCard.faceBImageFile)" :alt="businessCard.faceBImageAlt" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p :class="'vertical-caption ' + businessCard.captionPosition">{{ businessCard.caption }}</p>
  </div>
</template>

<script>
export default {
  name: 'ComponentProjectBusinessCard',
  components: {},
  data: () => ({}),
  mounted () {
    this.crossTiles(this.$refs.crossingTilesGrid, 50);
  },
  props: {
    businessCard: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {
    crossTiles(el, completionPercentage) {
      var tiles = el.querySelectorAll('.tile');

      for (var i = 0; i < tiles.length; i++) {
        var tile = tiles[i];
        var tileRow = Math.ceil((i+1) / (el.clientWidth / tile.clientWidth));
        if (tileRow % 2 == 0) {
          tile.style.transform = "translate(" + (completionPercentage/100*50 - 25) + "%, 0)"; // Translation on X axis from -25% to +25% for even lines (2-4-6-etc)
        } else {
          tile.style.transform = "translate(" + (completionPercentage/100*50 - 75) + "%, 0)"; // Translation on X axis from -25% to +25% for odd lines (1-3-5-etc)
        }
      }
    },
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .component-project-business-card{
    & > .grid{
      & > .row{
        & > .column{
          &:nth-child(1){
            padding: 10% 8%;

            .face-wrapper{
              padding: 15px;
              @include screens-m{ padding: 20px; }
              @include screens-l{ padding: 40px; }

              img{
                @include box-shadow(#{$layout_component-business-card_box-shadow});
              }
            }
          }

          &:nth-child(2){
            .crossing-tiles{
              height: 50vh;
              @include screens-landscape{ height: 100%; }
              position: relative;
              overflow: hidden;
              perspective: 1000px;
              perspective-origin: bottom right;

              .grid{
                position: absolute;
                width: 100%;
                height: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotateX(11deg) rotateY(0deg) rotateZ(30deg) matrix3d(0.98, 0, 0, 0, -0.3, 0.98, 0, 0, 0, 0, 0.96, 0, 0, 0, 0, 1);
                
                .row{
                  @include flex-direction(row);
                  justify-content: left;
                  transform: scale(2.5);
                  padding: calc(#{$layout_component-business-card_tiles_gutter_s} / 2);
                  @include screens-m { padding: calc(#{$layout_component-business-card_tiles_gutter_m} / 2); }
                  @include screens-l { padding: calc(#{$layout_component-business-card_tiles_gutter_l} / 2); }

                  .tile{
                    width: calc(100% / #{$layout_component-business-card_tiles_nb-per-line});
                    padding: calc(#{$layout_component-business-card_tiles_gutter_s} / 2);
                    @include screens-m { padding: calc(#{$layout_component-business-card_tiles_gutter_m} / 2); }
                    @include screens-l { padding: calc(#{$layout_component-business-card_tiles_gutter_l} / 2); }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>