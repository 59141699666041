<template>
  <div class="component-project-thumbnail link" @click="clickProjectThumbnail('projects', projectId)">
    <div class="background">
      <img :src="require('@/images/projects/'+project.thumbnail.background.imageFile)" :alt="project.common.background.imageAlt" />
    </div>
    <div class="overlay" v-if="project.common.overlay.opacity" :style="{'opacity': project.common.overlay.opacity}"></div>
    <div class="details">
      <div class="v-align-wrapper">
        <div>
          <h3 v-html="project.common.title"></h3>
          <p v-html="project.common.subtitle"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentProjectThumbnail',
  components: {},
  data: () => ({}),
  props: {
    project: {
      type: Object,
      default: null
    },
    projectId: {
      type: Number,
      default: 0
    }
  },
  computed: {},
  methods: {
    clickProjectThumbnail(type, ref) {
      this.$emit('click-project-thumbnail', { type: type, ref: ref })
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .component-project-thumbnail{
    position: relative;

    & > *{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .background,
    .overflow{
      img{
        width: 100%; /* Needed for object-fit to work */
        height: 100%; /* Needed for object-fit to work */
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    .overlay{
      background-color: black;
    }

    .details{
      text-align: center;
    }
  }
</style>