<template>
  <svg version="1.1" class="icon-cross" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
      x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <path d="M76.5,27.7L27.7,76.5c-1.2,1.2-3,1.2-4.2,0l0,0c-1.2-1.2-1.2-3,0-4.2l48.8-48.8c1.2-1.2,3-1.2,4.2,0l0,0C77.7,24.6,77.7,26.5,76.5,27.7z"/>
    <path d="M23.5,27.7l48.8,48.8c1.2,1.2,3,1.2,4.2,0l0,0c1.2-1.2,1.2-3,0-4.2L27.7,23.5c-1.2-1.2-3-1.2-4.2,0l0,0C22.3,24.6,22.3,26.5,23.5,27.7z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCross',
  components: {},
  data: () => ({}),
  props: {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .icon-cross{
    fill: black;
  }
</style>