<template>
  <svg version="1.1" class="icon-quote" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <defs>
      <linearGradient id="icon-quote-gradient" x1="0%" y1="0%" x2="10%" y2="100%" gradientUnits="userSpaceOnUse">
          <stop offset="10%" class="color-1" />
          <stop offset="40%" class="color-2" />
          <stop offset="55%" class="color-3" />
          <stop offset="72%" class="color-4" />
          <stop offset="72.01%" class="color-5" />
          <stop offset="78%" class="color-6" />
          <stop offset="95%" class="color-7" />
      </linearGradient>
    </defs>

    <g>
<path d="M78.9,92.5c8.8,0,19.4-7.7,19.4-19.8c0-11.6-7.5-18.9-16.3-19.6c-3.7-0.3-8.2,0.6-8.2,0.6s0.3-8.5,7.4-21.4
	c5-9,12.2-18.5,12.2-18.5l-8.2-6.2c0,0-11.2,11-18.4,24.8c-5.4,10.3-10.1,23.5-10.1,36C56.7,83,66.4,92.5,78.9,92.5z"/>
<path d="M23.9,92.5c8.8,0,19.4-7.7,19.4-19.8c0-11.6-7.5-18.9-16.3-19.6c-3.7-0.3-8.2,0.6-8.2,0.6s0.3-8.5,7.4-21.4
	c5-9,12.2-18.5,12.2-18.5l-8.2-6.2c0,0-11.2,11-18.4,24.8C6.4,42.6,1.7,55.8,1.7,68.3C1.7,83,11.3,92.5,23.9,92.5z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconQuote',
  components: {},
  data: () => ({}),
  props: {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .icon-quote{
    fill: url(#icon-quote-gradient);

    .color-1{ stop-color: #a3dfe7; }
    .color-2{ stop-color: #cbe7bd; }
    .color-3{ stop-color: #f0e5ab; }
    .color-4{ stop-color: #ecbec4; }
    .color-5{ stop-color: #dfaab8; }
    .color-6{ stop-color: #cd97ce; }
    .color-7{ stop-color: #9894d8; }
  }
</style>