<template>
  <section :class="'section-project-gallery background-' +  project.gallery.backgroundTeint" :style="{'background-color': project.gallery.backgroundColor}">
    <div class="content-wrapper">
      <div class="grid">
        <div v-for="(section, index) in project.gallery.sections" :key="index" class="row with-gutter">
          <div v-for="(subsection, index) in section.subsections" :key="index" class="column">
            <ComponentProjectBrandGuidelines v-if="subsection.type === 'brand-guidelines'" :brandGuidelines="subsection.data" />
            <ComponentProjectBusinessCard v-if="subsection.type === 'business-card'" :businessCard="subsection.data" />
            <ComponentProjectVisual v-if="subsection.type === 'visual'" :visual="subsection.data" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ComponentProjectBrandGuidelines from '@/components/_ComponentProjectBrandGuidelines.vue'
import ComponentProjectBusinessCard from '@/components/_ComponentProjectBusinessCard.vue'
import ComponentProjectVisual from '@/components/_ComponentProjectVisual.vue'

export default {
  name: 'sectionProjectGallery',
  components: {
    ComponentProjectBrandGuidelines,
    ComponentProjectBusinessCard,
    ComponentProjectVisual
  },
  data: () => ({}),
  props: {
    project: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .section-project-gallery{
    & > .content-wrapper{
      & > .grid{
        & > .row {
          flex-wrap: nowrap;
        }
      }
    }
    
    .component-project-brand-guidelines,
    .component-project-business-card,
    .component-project-visual{
      position: relative;
      @include box-shadow(#{$layout_section-project-gallery_element_box-shadow});
    }

    &.background-light{
      p{ color: #3B3B3B; }
    }
    &.background-dark{ 
      p{ color: #FFFFFF; }
    }
  }
</style>