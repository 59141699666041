<template>
  <svg version="1.1" class="icon-malt" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <path class="st0" d="M81.6,18.5c-6.7-6.7-13.9-2.4-18.4,2.1L42.1,41.8L20.9,63c-4.5,4.5-9.2,11.3-2.1,18.4c7.1,7.1,13.9,2.4,18.4-2.1L58.3,58l21.2-21.2C84,32.4,88.3,25.2,81.6,18.5z"/>
    <path class="st0" d="M41.1,16.8l9,9l9.1-9.1c0.6-0.6,1.2-1.2,1.9-1.7c-1-4.8-3.7-9.2-11-9.2c-7.3,0-10.1,4.4-11,9.2C39.8,15.5,40.5,16.1,41.1,16.8z"/>
    <path class="st0" d="M59.2,83.4l-9.1-9.1l-9,9c-0.7,0.7-1.4,1.3-2,1.9c1,4.9,3.9,9.4,11,9.4c7.1,0,10-4.5,11-9.5C60.5,84.5,59.9,84,59.2,83.4z"/>
    <path class="st0" d="M16.9,59l20.5-20.5H20.1c-6.3,0-14.5,2-14.5,11.5c0,7.1,4.5,10,9.5,11C15.6,60.3,16.2,59.6,16.9,59z"/>
    <path class="st0" d="M85.2,39c-0.5,0.6-1.1,1.3-1.7,1.9L62.9,61.5h17c6.3,0,14.5-1.5,14.5-11.5C94.4,42.7,90,39.9,85.2,39z"/>
    <path class="st0" d="M20.1,32.8h23l3.1-3.1l-9-9c-4.5-4.5-11.3-9.2-18.4-2.1c-5.2,5.2-4,10.2-1.3,14.3C18.3,32.9,19.2,32.8,20.1,32.8z"/>
    <path class="st0" d="M79.9,67.1l-22.7,0l-3.1,3.1l9.1,9.1c4.5,4.5,11.6,8.8,18.4,2.1c5-5,3.9-10.3,1.1-14.5C81.8,67.1,80.9,67.1,79.9,67.1z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMalt',
  components: {},
  data: () => ({}),
  props: {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .icon-malt{}
</style>