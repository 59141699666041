import Vue from 'vue'
import VueGtag from "vue-gtag";
import VueI18n from 'vue-i18n'

// Data & traductions
import DataEn from '@/content/en.json' // @ is an alias for "/src"
import DataFr from '@/content/fr.json'

// Views
import ViewHome from './ViewHome.vue'

// Style
import '@/scss/base.scss';
import '@/scss/layout.scss';
import '@/scss/typo.scss';

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueGtag, {config: {id: "G-GG9QLETP64" }});

const messages = {
  fr: {
    ...DataFr
  },
  en: {
    ...DataEn
  }
}

const i18n = new VueI18n({
  locale: 'fr',
  messages
});

const perspectiveAmplitude = 3; // perspectiveAmplitude = number between 1 and 100 (the bigger the number, the stronger the effect)

Vue.directive('mousemove', {
  inserted: function (el, binding) {
    let handleMousemove = function() {
      var elementFrame = el.getBoundingClientRect()

      switch (binding.value.trigger) {
        // Trigger "hover-position"
        case 'hover-position':
          var mousePosition = { x: event.clientX, y: event.clientY },
              mouseHoverElement = (mousePosition.x >= elementFrame.left) && (mousePosition.x <= elementFrame.right) && (mousePosition.y >= elementFrame.top) && (mousePosition.y <= elementFrame.bottom)

          if(mouseHoverElement){
            var containerSize = { x: el.clientWidth, y: el.clientHeight },
                mouseRelativePosition = { x: mousePosition.x - elementFrame.left, y: mousePosition.y - elementFrame.top }, // relative mouse position within the container
                mouseRelativePositionAxis = { x: (mouseRelativePosition.x-containerSize.x/2)/(containerSize.x/2), y: (containerSize.y/2-mouseRelativePosition.y)/(containerSize.y/2) } // Mouse relative positions on an axis with a (-1/+1) scale, with (0,0) beeing the center of the container, (-1,1) its bottom left point and (1,1) its top right)

            // Update class names for CSS transitions helpers
            if(el.classList.contains("mouse-out")){
              el.classList.remove("mouse-out");
              el.classList.add("mouse-hover", "mouse-enter");
              setTimeout(function() { el.classList.remove("mouse-enter"); }, 250);
            }

            switch (binding.value.animation) {
              // Animation "visibility"
              case 'perspective':
                el.style.setProperty('perspective', Math.round(Math.sqrt(containerSize.x*containerSize.x + containerSize.y*containerSize.y))*(perspectiveAmplitude/25) + 'px');
                for (var i = 0; i < el.children.length; i++) {
                  el.children[i].style.setProperty('transform', 'matrix3d(0.98,0,' + mouseRelativePositionAxis.x*-perspectiveAmplitude*0.00425 + ',0,' + mouseRelativePositionAxis.x*perspectiveAmplitude*0.00075 + ',0.98,' + mouseRelativePositionAxis.y*perspectiveAmplitude*0.00425 + ',0,' + mouseRelativePositionAxis.x*perspectiveAmplitude*0.00425 + ',' + mouseRelativePositionAxis.y*-perspectiveAmplitude*0.00425 + ',0.96,0, 0,0,0,1)');
                }
                break;
              default:
            }
          } else {
            // Update class names for CSS transitions helpers
            if(el.classList.contains("mouse-hover")){
              el.classList.remove("mouse-hover");
              el.classList.add("mouse-out", "mouse-leave");
              setTimeout(function() { el.classList.remove("mouse-leave"); }, 250);
              // Disable perspective effect
              for (i = 0; i < el.children.length; i++) {
                el.children[i].style.setProperty('transform', 'matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1)');
              }
            }
          }
          break;
        default:
      }
    }

    el.classList.add("mouse-out"); // Setup class names for CSS transitions helpers
    window.addEventListener('mousemove', handleMousemove); // Add and event listener for mousemove
  }
});

Vue.directive('pagescroll', {
  inserted: function (el, binding) {
    let handleScroll = function() {
      var elementFrame = el.getBoundingClientRect(),
          viewportHeight = (window.innerHeight || document.documentElement.clientHeight)

      switch (binding.value.trigger) {
        // Trigger "reached-once"
        case 'reached-once':
          if(elementFrame.top - viewportHeight <= 0 && elementFrame.bottom >= 0){
            switch (binding.value.animation) {
              // Animation "visibility"
              case 'width':
                var widthPercentage = binding.value.widthPercentage;
                el.style.width = widthPercentage;
                break;
              default:
            }
            // Disable even listener as the element has been reached once
            window.removeEventListener('scroll', handleScroll);
          }
          break;
        // Trigger "partially-visible"
        case 'partially-visible':
          var scrollAnimationStart = window.scrollY + elementFrame.top - viewportHeight,
              scrollAnimationEnd = window.scrollY + elementFrame.top + elementFrame.height,
              scrollAnimationCompletionPercentage = ((window.scrollY - scrollAnimationStart) / (scrollAnimationEnd - scrollAnimationStart)) * 100

          if (window.scrollY > scrollAnimationStart && window.scrollY <= scrollAnimationEnd) {
            switch (binding.value.animation) {
              // Animation "crossing-tiles"
              case 'crossing-tiles':
                var tiles = el.querySelectorAll('.tile');
                for (var i = 0; i < tiles.length; i++) {
                  var tile = tiles[i];
                  var tileRow = Math.ceil((i+1) / (el.clientWidth / tile.clientWidth));
                  if (tileRow % 2 == 0) {
                    tile.style.transform = "translate(" + (scrollAnimationCompletionPercentage/100*50 - 25) + "%, 0)"; // Translation on X axis from -25% to +25% for even lines (2-4-6-etc)
                  } else {
                    tile.style.transform = "translate(" + (25 - scrollAnimationCompletionPercentage/100*50) + "%, 0)"; // Translation on X axis from +25% to -25% for odd lines (1-3-5-etc)
                  }
                }
                break;
              default:
            }
          }
          break;
        default:
      }
    }

    window.addEventListener('scroll', handleScroll); // Add and event listener for scroll
    window.dispatchEvent(new CustomEvent('scroll')); // Simulate a scroll to make sure that animations are loaded properly when page loads
  }
});

new Vue({
  render: h => h(ViewHome),
  data: () => ({}),
  methods: {},
  i18n
}).$mount('#view-home')
