<template>
  <div id="popup" class="popup-menu">
    <ComponentMenu version="popup" :data="$t('componentMenu')" @scroll-to="scrollTo" />
    <ComponentButtonIcon icon="IconCross" @click-button-icon="clickButtonIcon"  />
  </div>
</template>

<script>
import ComponentMenu from '@/components/_ComponentMenu.vue'
import ComponentButtonIcon from '@/components/_ComponentButtonIcon.vue'

export default {
  name: 'PopupMenu',
  components: {
    ComponentMenu,
    ComponentButtonIcon
  },
  data: () => ({}),
  props: {},
  computed: {},
  methods: {
    scrollTo(payload) {
      this.$emit('click-button-icon')
      this.$emit('scroll-to', { ref: payload.ref, delay: 250 })
    },
    clickButtonIcon() {
      this.$emit('click-button-icon')
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .popup-menu{
    .component-menu{
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;

      .grid{
        height: 100%;

        .row{
          height: 100%;
          flex-wrap: wrap; // If there is not enough space horizontally, the rest of the content will keep going underneath

          .column{
            width: 100%;
            height: initial;
            flex: auto;
            @include screens-landscape { width: 50%; }
          }
        }
      }
    }
  }
</style>