<template>
  <div class="component-project-thumbnail-list">
    <div class="grid">
    <div class="row">
      <div v-for="(project, index) in projects" :key="index" class="column width-4">
        <ComponentProjectThumbnail
          :project="project"
          :projectId="(index >= projectIdCurrent && (projectIdCurrent !== null)) ? (index+1) : index"
          v-mousemove="{trigger:'hover-position', animation:'perspective' }"
          @click-project-thumbnail="clickProjectThumbnail" />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ComponentProjectThumbnail from '@/components/_ComponentProjectThumbnail.vue'

export default {
  name: 'ComponentProjectThumbnailList',
  components: {
    ComponentProjectThumbnail
  },
  data: () => ({}),
  props: {
    projectIdCurrent: {
      type: Number,
      default: null
    },
    projects: {
      type: Array,
      default: null
    }
  },
  computed: {},
  methods: {
    clickProjectThumbnail(payload) {
      this.$emit('click-project-thumbnail', payload)
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .component-project-thumbnail-list{ 
    .grid{ 
      .row{ 
        @include screens-m-portrait {
          @include flex-direction(row);
        }

        .column{
          padding: $layout_section-projects_thumbnails_gutter/2;

          .component-project-thumbnail{
            padding-bottom: $layout_component-thumbnails-list_thumbnails_width-height-ratio;  /* Specify a width/height ratio for perspective hover effect to work properly */

            & > *{
              transition-property: transform;
              transition-duration: 0;
              transition-delay: 0;
              transition-timing-function: ease-out;
            }

            &.mouse-enter > *{
              transition-duration: 250ms;
            }

            &.mouse-out > *{
              transition-duration: 250ms;
            }
          }
        }
      }
    }
  }
</style>