
<template>
  <div class="component-carousel" ref="carousel">
    <div class="slides grid">
      <div class="row">
        <div class="column" v-for="(slide, index) in slides" :key="index">
          <div class="content-wrapper v-align-wrapper">
            <div>
              <div class="text-wrapper">
                <IconQuote />
                <p class="text" v-html="slides[index].text"></p>
                <p class="author" v-html="slides[index].author"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="controls">
      <div class="grid">
        <div class="row">
          <div v-for="(slide, index) in slides" :key="index" :class="index === currentSlideId ? 'column link active' : 'column link'"  @click="switchToSlide(index)">
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconQuote from '@/components/__IconQuote.vue'

export default {
  name: 'ComponentCarousel',
  components: {
    IconQuote
  },
  data: () => ({
    carousel: null,
    currentSlideId: null,
    scrollingTimeout: null,
    autoPlayTimeout: null
  }),
  props: {
    slides: {
      type: Array,
      default: null
    },
    initialSlideId: {
      type: Number,
      default: 0
    },
    autoPlay: {
      type: Boolean,
      default: true
    },
    autoPlaySpeed: {
      type: Number,
      default: 10000
    }
  },
  computed: {},
  created() {
    this.currentSlideId = this.initialSlideId;
  },
  mounted() {
    this.carousel = this.$refs["carousel"];
    // Initialize carousel scrolling amount depending on initialSlideId
    this.carousel.scrollTo({ left: this.currentSlideId * this.carousel.clientWidth });
    // Add and event listener for wheel
    this.carousel.addEventListener('scroll', this.handleScroll);
    // Start autoplay if enabled
    if(this.autoPlay){ this.startAutoPlay(); }
  },
  methods: {
    // Autoplay methods
    startAutoPlay() {
      this.autoPlayTimeout = setTimeout(this.switchToNextSlide, this.autoPlaySpeed);
    },
    stopAutoPlay() {
      clearTimeout(this.autoPlayTimeout);
    },
    // User interaction methods
    handleScroll() {
      var _this = this,
          nextSlideId = Math.ceil(Math.max(0, this.carousel.scrollLeft/this.carousel.clientWidth - 0.5));

      this.currentSlideId = nextSlideId; // / Update current slide ID
      clearTimeout(this.scrollingTimeout); // Reset scrollingTimeout while scrolling
      // Check if we are not scrolling for more than 50 ms and if yes, trigger animations
      this.scrollingTimeout = setTimeout(function() {
        // Swipe to next active slide (magnetisation)
        _this.switchToSlide(nextSlideId);
      }, 50);
    },
    // Carousel methods
    switchToPreviousSlide() {
      var nextSlideId;
      if(this.currentSlideId > 0){
        nextSlideId = this.currentSlideId - 1;
      } else {
        nextSlideId = this.slides.length;
      }
      this.switchToSlide(nextSlideId);
    },
    switchToNextSlide() {
      var nextSlideId;
      if(this.currentSlideId < this.slides.length-1){
        nextSlideId = this.currentSlideId + 1;
      } else {
        nextSlideId = 0;
      }
      this.switchToSlide(nextSlideId);
    },
    switchToSlide(slideId) {
      if(this.autoPlay){ this.stopAutoPlay(); } // Stop autoplay if enabled
      if(this.currentSlideId !== slideId){ this.currentSlideId = slideId; } // Update current slide ID if not done yet through scrolling
      // Swipe accordingly (assuming that all slides are 100% wide)
      this.carousel.scrollTo({
        left: slideId * this.carousel.clientWidth,
        behavior: 'smooth'
      })
      if(this.autoPlay){ this.startAutoPlay(); } // Restart autoplay if enabled
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .component-carousel{
    text-align: center;
    -ms-overflow-style: none;  // Hide scrollbar for IE and Edge
    scrollbar-width: none;  // Hide scrollbar for Firefox
    &::-webkit-scrollbar { display: none; } // Hide scrollbar for Chrome, Safari and Opera
    overflow-x: scroll;
    
    .slides{
      padding-bottom: 35px; // This is to make sure that content won't go above controls
      @include screens-l { padding-bottom: 50px; }

      & > .row{
        @include flex-direction(row);
        flex-wrap: nowrap;

        & > .column{
          width: 100%;
          @include flex(none);

          .content-wrapper{
            @include screens-l { padding: $layout_sections-content-wrapper_padding_l * 0.85; }

            .text-wrapper{
              margin: 0 auto;
              @include screens-l { max-width: 850px; }

              .icon-quote{
                width: $layout_section-testimonials_inverted-commas_width_s;
                height: auto;
                margin-bottom: 30px;
                @include screens-m { width: $layout_section-testimonials_inverted-commas_width_m; }
                @include screens-l { width: $layout_section-testimonials_inverted-commas_width_l; }
              }

              p{
                line-height: 1.3em;
                @include screens-m { line-height: 1.5em; }
                @include screens-l { line-height: 1.5em; }

                &.text{
                  font-size: $typo_p_font-size_s * 1.2;
                  font-weight: 500;
                  margin-bottom: 18px;
                  @include screens-m { font-size: $typo_p_font-size_m * 1.2; }
                  @include screens-l { font-size: $typo_p_font-size_l * 1.2; }
                }
              }
            }
          }
        }
      }
    }
    
    .controls{
      position: absolute;
      left: 50%;
      bottom: $layout_section-testimonials_carousel-controls_position-bottom_s;
      transform: translate(-50%, 0);
      @include screens-m { bottom: $layout_section-testimonials_carousel-controls_position-bottom_m; }
      @include screens-l { bottom: $layout_section-testimonials_carousel-controls_position-bottom_l; }

      .grid{
        @include flex-direction(row);

        .row{
          @include flex-direction(row);
          flex-wrap: wrap; // Make sure it will stay in one line

          .column{
            padding: 4px; // This is to make sure that the area that we can click on is big enough
            margin: 0 1px;
            
            .dot{
              width: 4px;
              height: 4px;
              border-radius: 4px;
              background-color: $layout_section-testimonials_carousel-controls-dots_background-color;
            }

            &.active{
              .dot{ background-color: $layout_section-testimonials_carousel-controls-dots_background-color-active; }
            }
          }
        }
      }
    }
  }
</style>