<template>
  <section class="section-project-hero full-screen">
    <div class="background">
      <img :src="require('@/images/projects/'+project.hero.background.imageFile)" :alt="project.common.background.imageAlt" loading="eager" />
    </div>
    <div class="overlay" v-if="project.common.overlay.opacity" :style="{'opacity': project.common.overlay.opacity}"></div>
    <div class="details v-align-wrapper">
      <div>
        <h3 v-html="project.common.title"></h3>
        <p v-html="project.common.subtitle"></p>
      </div>
    </div>
    <IconMouseScroll teint="light" />
    <p class="copyright" v-if="project.common.background.imageCopyright" v-html="project.common.background.imageCopyright"></p>
  </section>
</template>

<script>
import IconMouseScroll from '@/components/__IconMouseScroll.vue'

export default {
  name: 'sectionProjectHero',
  components: {
    IconMouseScroll
  },
  data: () => ({}),
  props: {
    project: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .section-project-hero{
    .background,
    .overlay,
    .details{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .background,
    .overlay{
      img{
        width: 100%; /* Needed for object-fit to work */
        height: 100%; /* Needed for object-fit to work */
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    .overlay{
      background-color: black;
    }

    .details{
      text-align: center;

      h3{
        font-size: $layout_section-project-hero_title_font-size_s;
        @include screens-m { font-size: $layout_section-project-hero_title_font-size_m; }
        @include screens-l { font-size: $layout_section-project-hero_title_font-size_l; }
      }

      p{
        font-size: $layout_section-project-hero_subtitle_font-size_s;
        @include screens-m { font-size: $layout_section-project-hero_subtitle_font-size_m; }
        @include screens-l { font-size: $layout_section-project-hero_subtitle_font-size_l; }
      }
    }

    .copyright{
      position: absolute;
      bottom: -5px;
      left: calc(100% - 18px);
      font-size: 10px;
      transform: rotate(-90deg);
      transform-origin: 0 0;
      white-space: nowrap; /* Make sure it will be written on one line */
      opacity: 0.5;
    }
  }
</style>