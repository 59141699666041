<template>
  <section class="section-clients">
    <div class="grid" v-pagescroll="{trigger:'partially-visible', animation:'crossing-tiles' }">
      <div class="row">
        <div v-for="(client, index) in randomizedClients" :key="index" class="tile">
          <img :src="require('@/images/clients/'+client.logoImage)" :alt="client.alt" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionClients',
  components: {},
  data: () => ({}),
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    randomizedClients(){
      const clientsArray = this.data.clients;
      return clientsArray.sort(
        function(){
          return 0.5 - Math.random()
        }
      );
    }
  },
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .section-clients{
    height: $layout_section-clients_height_portrait;
    background-color: $layout_colours-grey-light;
    @include screens-landscape { height: $layout_section-clients_height_landscape; }

    .grid{
      height: inherit;
      
      .row{
        @include flex-direction(row);
        justify-content: left;
        padding: calc(#{$layout_section-clients_tiles_gutter_s} / 2);
        transform: scale(1.25) translateY(-5vw) rotate($layout_section-clients_grid-rotation);
        @include screens-m { padding: calc(#{$layout_section-clients_tiles_gutter_m} / 2); }
        @include screens-l { padding: calc(#{$layout_section-clients_tiles_gutter_l} / 2); }

        .tile{
          width: calc(100% / #{$layout_section-clients_tiles_nb-per-line_portrait});
          padding: calc(#{$layout_section-clients_tiles_gutter_s} / 2);
          @include screens-m { padding: calc(#{$layout_section-clients_tiles_gutter_m} / 2); }
          @include screens-l { padding: calc(#{$layout_section-clients_tiles_gutter_l} / 2); }
          @include screens-landscape { width: calc(100% / #{$layout_section-clients_tiles_nb-per-line_landscape}); }
        }
      }
    }
  }
</style>