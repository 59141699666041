<template>
  <div class="component-logo">
    <IconLogo />
  </div>
</template>

<script>
import IconLogo from '@/components/__IconLogo.vue'

export default {
  name: 'ComponentLogo',
  components: {
    IconLogo
  },
  data: () => ({}),
  props: {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .component-logo{
    position: absolute;
    top: 0;
    left: 0;
    width: $layout_header-elements_width_s;
    height: $layout_header-elements_height_s;
    padding: $layout_header-elements_padding_s;
    text-align: center;
    background-color: black;
    z-index: 2;
    @include screens-m { 
      width: $layout_header-elements_width_m;
      height: $layout_header-elements_height_m;
      padding: $layout_header-elements_padding_m;
    }
    @include screens-l { 
      width: $layout_header-elements_width_l;
      height: $layout_header-elements_height_l;
      padding: $layout_header-elements_padding_l;
    }
  }
</style>