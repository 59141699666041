<template>
  <svg version="1.1" class="icon-hamburger" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
      x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <path d="M84.53,52.97H15.47c-1.64,0-2.97-1.33-2.97-2.97v0c0-1.64,1.33-2.97,2.97-2.97h69.05c1.64,0,2.97,1.33,2.97,2.97v0
      C87.5,51.64,86.17,52.97,84.53,52.97z"/>
    <path d="M84.32,76.53h-69c-1.66,0-3-1.34-3-3v0c0-1.66,1.34-3,3-3h69c1.66,0,3,1.34,3,3v0C87.32,75.18,85.98,76.53,84.32,76.53z"/>
    <path d="M84.32,29.47h-69c-1.66,0-3-1.34-3-3v0c0-1.66,1.34-3,3-3h69c1.66,0,3,1.34,3,3v0C87.32,28.13,85.98,29.47,84.32,29.47z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconHamburger',
  components: {},
  data: () => ({}),
  props: {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .icon-hamburger{
    fill: black;
  }
</style>