<template>
  <section class="section-project-details">
    <div class="content-wrapper">
      <div class="grid">
        <div v-for="(section, index) in project.details.sections" :key="index" class="row with-gutter">
          <div v-for="(subsection, index) in section.subsections" :key="index" class="column">
            <h3 v-html="subsection.title"></h3>
            <p v-if="subsection.content" v-html="subsection.content"></p>
            <ul v-if="subsection.list">
              <li v-for="(listItem, index) in subsection.list" :key="index">
                <strong v-html="listItem.title"></strong>: <span v-html="listItem.content"></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'sectionProjectDetails',
  components: {},
  data: () => ({}),
  props: {
    project: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .section-project-details{
    background-color: $layout_section-project-details_background-color;

    h3, p, ul li, a {
      color: $typo_color-alt;
    }

    .grid{
      .row.with-gutter:first-child{
        .column:last-child{
          @include screens-s { padding-bottom: 0!important; }
        }
      }

      .row:not(:first-child){
        .column{
          h3{ font-size: 22px; }
          a{ text-decoration: underline; }
        }
      }
    }
  }
</style>