<template>
  <svg version="1.1" class="icon-linkedin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <path d="M74.3,7.9H25.2c-9.7,0-17.6,7.9-17.6,17.6v49.1c0,9.7,7.9,17.6,17.6,17.6h49.1c9.7,0,17.6-7.9,17.6-17.6V25.4C91.9,15.7,84,7.9,74.3,7.9z M35.7,74.6H25.2V36h10.5V74.6z M30.5,31.5c-3.4,0-6.1-2.8-6.1-6.2s2.8-6.2,6.1-6.2s6.1,2.8,6.1,6.2S33.8,31.5,30.5,31.5z M77.8,74.6H67.3V54.9c0-11.8-14-10.9-14,0v19.7H42.7V36h10.5v6.2c4.9-9.1,24.6-9.7,24.6,8.7V74.6z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconLinkedin',
  components: {},
  data: () => ({}),
  props: {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .icon-linkedin{}
</style>