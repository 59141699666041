<template>
  <section class="section-projects">
    <div class="content-wrapper">
      <ComponentProjectThumbnailList :projects="data.projects" @click-project-thumbnail="clickProjectThumbnail" />
    </div>
  </section>
</template>

<script>
import ComponentProjectThumbnailList from '@/components/_ComponentProjectThumbnailList.vue'

export default {
  name: 'SectionProjects',
  components: {
    ComponentProjectThumbnailList
  },
  data: () => ({}),
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {
    clickProjectThumbnail(payload) {
      this.$emit('click-project-thumbnail', payload)
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .section-projects{
    background-color: $layout_section-projects_background-color;

    .content-wrapper{
      padding: calc(#{$layout_sections-content-wrapper_padding_s} - #{$layout_section-projects_thumbnails_gutter}/2);
      @include screens-m { padding: calc(#{$layout_sections-content-wrapper_padding_m} - #{$layout_section-projects_thumbnails_gutter}/2); }
      @include screens-l { padding: calc(#{$layout_sections-content-wrapper_padding_l} - #{$layout_section-projects_thumbnails_gutter}/2); }

      .component-thumbnails-list{ 
        .grid{
          .row{
            @include screens-landscape {
              justify-content: center; /* Center the flex items if they don't fill the available space (ex: if there is only one on the last) */
            }
            
            .column{
              @include screens-m-portrait { width: 50%; }

              .component-project-thumbnail{
                .details{
                  h3{ 
                    font-size: $layout_section-projects_thumbnails-title_font-size_s; 
                    @include screens-m { font-size: $layout_section-projects_thumbnails-title_font-size_m; }
                    @include screens-l { font-size: $layout_section-projects_thumbnails-title_font-size_l; }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>