<template>
  <svg version="1.1" class="icon-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
      x="0px" y="0px" viewBox="0 0 1000 575" style="enable-background:new 0 0 1000 575;" xml:space="preserve">
    <defs>
      <linearGradient id="icon-logo-gradient" x1="0%" y1="0%" x2="100%" y2="33%" gradientUnits="userSpaceOnUse">
          <stop offset="10%" class="color-1" />
          <stop offset="25%" class="color-2" />
          <stop offset="50%" class="color-3" />
          <stop offset="70%" class="color-4" />
          <stop offset="80%" class="color-5" />
      </linearGradient>
    </defs>

    <g id="word-online">
      <!-- Letter O -->
      <path d="M95,213c-13,0-24.9-2.7-35.5-8s-19.8-12.3-27.4-21s-13.5-18.6-17.8-29.7C10.1,143.2,8,131.7,8,119.9c0-12,2.2-23.6,6.6-34.7s10.5-21,18.3-29.5s17.1-15.3,27.8-20.3s22.4-7.6,35.1-7.6c13,0,24.9,2.7,35.5,8s19.8,12.4,27.4,21.1c7.6,8.7,13.5,18.7,17.6,29.9s6.2,22.5,6.2,34c0,12-2.2,23.6-6.6,34.7c-4.4,11.2-10.4,21-18.1,29.5s-17,15.3-27.7,20.5C119.4,210.4,107.7,213,95,213z M51,120.4c0,7,1,13.9,3,20.5s4.8,12.5,8.5,17.6c3.7,5.1,8.3,9.3,13.9,12.4s12,4.6,19.2,4.6c7.4,0,13.8-1.6,19.3-4.9c5.5-3.2,10.1-7.5,13.8-12.7c3.7-5.2,6.4-11.2,8.2-17.8s2.7-13.3,2.7-20.2c0-7-1-13.9-3-20.5c-2-6.6-4.9-12.4-8.6-17.5c-3.8-5.1-8.4-9.1-13.9-12.1c-5.5-3-11.7-4.5-18.8-4.5c-7.4,0-13.8,1.6-19.3,4.8c-5.5,3.2-10.1,7.3-13.8,12.5s-6.5,11-8.4,17.6C51.9,106.8,51,113.5,51,120.4z"/>
      <!-- Letter N -->
      <path d="M249.4,107v104.5h-42.2V28.8h32.9l85.2,107.3V28.8h42.2v182.7h-34L249.4,107z"/>
      <!-- Letter L -->
      <path d="M402.5,211.5V28.8h42.2v145.7h88.5v37.1H402.5z"/>
      <!-- Letter I -->
      <path d="M554.1,211.5V28.8h42.2v182.7H554.1z"/>
      <!-- Letter N -->
      <path d="M673.5,107v104.5h-42.2V28.8h32.9l85.2,107.3V28.8h42.2v182.7h-34L673.5,107z"/>
      <!-- Letter E -->
      <path d="M955,174.4v37.1H826.6V28.8h126.1v37.1h-83.9v35.5h72.1v34.2h-72.1v38.9C868.8,174.4,955,174.4,955,174.4z"/>
    </g>
    <g id="logo-word-stories">
      <!-- Letters s + t -->
      <path d="M363.9,547c0-40,44.9-205.4,65.1-258.2h-1.2c-15.6,16.5-43.1,46.8-95.1,97.5c14.1,5.2,21.7,16.2,21.7,27.8c0,35.4-69.1,131.4-125,131.4c-17.7,0-27.8-9.8-27.8-22.6c0-28.7,38.5-60.5,114.9-132.9c-47.7,0-140.9,39.1-184.3,39.1c-17.1,0-33-6.4-33-23.5c0-39.4,93.5-114,180-114c18.3,0,44,5.8,44,11.6c0,1.2-1.2,2.4-3.3,2.4c-0.6,0-1.5-0.3-2.2-0.6c-8.8-3.3-18-4.9-27.5-4.9c-76.7,0-176,76.4-176,109.7c0,7,2.7,8.2,5.8,9.2c3.3,0.6,7,0.9,10.7,0.9c42.2,0,133.8-36.7,181.5-36.7c3.7,0,7,0.3,10.7,0.6c31.8-30.2,64.5-62.7,93.5-95.1c-10.4-0.6-4.9-1.2-33.9-1.2h-17.4c-6.1,0-10.4-0.3-10.4-3.1c0-5.5,4.9-6.4,5.5-6.4c0.9,0,2.7,0.6,2.7,1.5c0,1.2-1.5,1.8-1.8,3.1l63.6-3.1c16.2-18.6,15-18.9,17.7-18.9c3.3,0,6.7,1.8,6.7,4.6l-4.3,14.7c97.2-0.9,204.5-5.2,204.5-5.2c2.1,0,4.9,1.5,4.9,3.4c0,4.3-195.3,9.2-211.5,10.1c-8.2,31.8-48.3,148.5-64.8,260.4c-0.3,2.2-0.3,4-0.3,6.4c0,1.8,0,4.3,0.9,5.8c4.6-1.5,7.9-6.4,9.2-9.2c0.3-0.9,1.5-1.5,2.7-1.5c2.4,0,5.2,1.5,5.2,3.3c0,0.3,0,0.6-0.3,0.9c-2.7,6.4-5.5,14.7-15,14.7C368.8,569,363.9,558.9,363.9,547z M345.6,413.4c0-10.7-5.8-19.3-17.7-22.3c-67.2,65.4-117.3,105.4-117.3,133.2c0,9.2,5.5,15,17.1,15C276.5,539.3,345.6,448,345.6,413.4z"/>
      <!-- Letters o + r -->
      <path d="M533.8,476.4c-1.5-0.9-2.7-1.8-3.3-3.4c-0.6-1.5-0.9-2.7-0.9-4.3c0-7,0.6-13.4,1.5-18.7c0.9-16.2,3.1-32.4,5.8-48.3c-10.7,8.9-20.5,13.4-29.3,13.4c-3.7-0.3-7-0.9-10.1-2.4c-1.8,4.6-3.7,9.2-5.8,13.7c-5.2,12.8-12.8,24.4-22.3,34.8c-6.4,6.4-12.2,9.5-18,9.5c-4.9,0-9.5-2.2-13.8-6.1c-4.3-5.2-6.4-12.8-6.4-22.6c0-22.6,8.2-41.6,25.1-57.2c5.2-5.2,10.1-7.7,14.7-7.7c3.7,0,6.7,1.2,8.8,4c0.3-6.4,1.8-12.8,4.6-19.9c2.7-6.7,6.1-10.1,10.7-10.1c7,0,10.4,6.7,10.4,19.8c0,11.9-2.2,23.8-6.1,35.8c2.2,1.2,4.3,1.8,6.7,1.8c2.4,0,4.9-0.3,7-1.2c10.7-4.3,19.3-10.7,26.3-19.2l1.2-5.8c3.1-16.5,9.2-24.8,18.6-24.8c2.2,0,4.3,0.6,6.1,1.8c1.8,1.5,2.7,3.1,2.7,4.6c0,2.2-1.2,3.3-3.7,3.3c-1.2,0-2.4-0.6-3.1-1.5c-1.5,1.5-3.1,4.3-4.3,8.8c-6.4,23.8-10.4,47.7-11.6,71.5c8.2-18.9,18.6-37.3,31.5-55.3c4-5.8,7-8.6,8.8-8.6c2.4,0,4.3,0.9,4.9,2.4c1.5,3.1,2.2,6.7,2.2,10.7c0,10.1,3.1,18.9,8.9,26c4,3.7,7.9,5.5,12.5,5.5c5.8,0,12.5-2.2,20.5-6.7l1.8-0.3c1.2,0,2.4,0.3,4,1.2c1.2,0.9,1.8,1.8,1.8,2.7c0,0.6-0.3,1.2-1.2,1.5c-9.8,5.8-18.3,8.6-25.4,8.6c-7.9,0-14.7-2.7-19.8-7.9c-8.2-7.9-12.2-18-12.5-30.6c-13.1,17.4-23.8,36.1-32.1,56.5c-1.8,4-3.3,8.2-4.9,12.8c-1.2,3.1-2.2,5.5-3.3,7.7c-1.2,3.3-2.7,4.9-4.9,4.9C536.5,477.6,535.3,477.3,533.8,476.4z M452.8,459.2c14.1-8.8,26-26.6,35.1-53.8c-4-4.6-6.7-10.1-7.9-16.5c-0.6,0.6-1.5,0.9-2.7,0.9c-2.7,0-4.6-1.2-4.9-3.3c-4.9,3.3-8.6,7-11,11c-11,15.3-16.5,30.9-16.5,46.8c0,7.9,1.2,13.4,3.3,16.8h-0.3l0.3,0.3h0.9L452.8,459.2z M496.8,365.1c0-2.2-0.3-4.6-0.9-7.3c-0.6,0.9-1.2,1.8-1.5,3.1c-3.7,8.6-5.5,16.5-5.5,23.5c0,4,0.6,7.9,2.2,11.9C495,383.2,496.8,372.8,496.8,365.1z"/>
      <!-- Letter i -->
      <path d="M633.1,466.6c-5.2-3.4-8.2-8.6-8.6-15.6c0-1.2,0-2.4,0.3-3.7c1.5-10.7,7.7-26.9,18.3-49.2c3.3-7.7,7.7-12.5,12.2-14.7c3.3,0,4.9,1.8,4.9,5.5v1.8c-0.3,2.4-1.2,5.2-2.7,8.2c-2.2,4.6-7,15.3-14.4,32.4c-3.1,7.9-4.9,14.1-5.5,18.6l-0.3,2.2v1.5c0,5.8,2.2,8.8,6.1,8.8c3.1,0,6.1-0.9,9.5-2.4c13.8-5.8,29.3-18.3,46.2-37.3c0.9-1.5,1.8-2.2,3.3-2.2c0.9,0,2.2,0.3,3.4,0.9c1.2,0.6,1.8,1.5,1.8,2.4c-0.3,0.3-0.6,0.9-0.9,1.2c-8.2,9.2-16.8,17.7-26,25.3c-8.9,8.3-19.3,14.1-30.6,17.4c-2.4,0.6-4.9,0.9-7.3,0.9C639.5,469,636.4,468.1,633.1,466.6z M662.1,367c-1.5-0.9-2.2-2.4-2.4-4.3c0.3-1.2,1.2-3.7,3.3-7c2.2-3.3,4.6-5.5,7.7-6.4c3.1,0,4.6,1.5,4.6,4.3v1.8c-0.3,2.4-1.5,5.2-3.1,8.6c-1.5,3.1-3.3,4.6-5.8,4.6C664.9,368.5,663.7,367.9,662.1,367z"/>
      <!-- Letter e -->
      <path d="M701.6,440c-2.7-4.6-4-9.8-4-15.3c0-10.4,4-20.2,11.9-29c5.2-5.8,11.6-10.1,18.7-12.8c2.4-0.9,4.9-1.2,7.3-1.2c3.1,0,5.8,0.6,8.6,1.8c4.3,2.2,6.4,5.5,6.4,9.8c0,2.2-0.3,4-1.2,5.5c-3.7,7.9-9.5,15-17.7,20.8c-7,5.8-15.3,9.5-24.1,10.4c0.3,4.6,2.1,8.8,5.5,12.2c3.3,2.4,7,3.7,10.7,3.7c9.5,0,25.4-7.9,47.4-24.1c0.6-0.3,1.2-0.6,1.8-0.6c1.5,0,2.7,0.3,4,1.2c1.2,0.9,1.8,2.2,1.8,3.1s-0.6,1.5-1.5,2.2c-0.9,0.9-2.2,1.8-3.3,2.7c-7.7,5.2-15.6,10.4-23.8,15c-7.3,4.6-15.3,7-23.8,7C715.3,452.2,707.3,448.2,701.6,440z M710.7,421.4c17.4-9.5,26.9-19.8,28.4-30.8l-0.3-0.6h-2.1l-0.6,0.3c-1.8,0-4.3,1.2-7,3.3c-11.3,6.7-18.3,16.5-21.1,29L710.7,421.4z"/>
      <!-- Letter s -->
      <path d="M788,492.9c0-10.4,5.5-21.7,16.2-33.6c-2.7-2.2-6.1-4-9.5-5.8c-16.2-8.6-24.1-19.2-24.1-31.8c0-9.2,3.3-18.6,9.8-27.8c8.8-13.1,18.9-19.9,29.9-19.9c5.2,0,10.1,1.5,14.1,4.9c4,3.3,6.1,7,6.1,10.4c0,4-1.8,5.8-5.5,5.8c-0.9,0-1.5-0.3-2.4-0.6c-0.9-0.6-1.5-2.2-2.2-4.6c-0.6-2.7-1.2-4.6-2.1-5.8c-0.9-0.9-3.1-1.5-6.1-1.5c-4.6,0.9-8.8,3.3-12.8,7.3c-11.3,10.1-17.1,21.7-17.4,34.2c0,7,2.1,12.2,6.1,15.9c4,3.7,8.2,7,13.4,9.8c2.7,1.5,5.2,3.1,7.3,4.6c13.1-12.8,27.5-23.8,43.1-33.3c0.3-0.3,0.9-0.6,1.5-0.6c0.9,0,2.2,0.3,3.3,0.9c0.9,0.9,1.5,1.8,1.5,2.7c0,0.6-0.3,1.2-0.9,1.5c-16.2,9.8-30.6,20.8-43.1,33.3c4,3.3,6.1,8.6,6.1,15c0,5.5-2.1,11-6.1,16.8c-6.1,7.9-11.6,11.9-17.4,11.9S788,499.3,788,492.9zM799,497.1c0.9-0.6,1.5-1.2,2.2-2.2c3.1-2.7,5.8-6.4,7.9-10.4s3.1-7.9,3.1-11.3s-0.9-6.4-2.7-9.2c-10.1,11.3-15.3,21.7-15.3,30.9c0,2.2,0.6,3.3,2.2,3.3C797.2,498.1,798.1,497.7,799,497.1z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconLogo',
  components: {},
  data: () => ({}),
  props: {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .icon-logo{
    fill: url(#icon-logo-gradient);

    .color-1{ stop-color: $layout_colours-blue-vivid; }
    .color-2{ stop-color: $layout_colours-green-vivid; }
    .color-3{ stop-color: $layout_colours-yellow-vivid; }
    .color-4{ stop-color: $layout_colours-orange-vivid; }
    .color-5{ stop-color: $layout_colours-purple-vivid; }
  }
</style>