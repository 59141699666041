<template>
  <div class="component-project-visual">
    <img :src="require('@/images/projects/'+visual.imageFile)" :alt="visual.imageAlt" />
    <p :class="'vertical-caption ' + visual.captionPosition">{{ visual.caption }}</p>
  </div>
</template>

<script>
export default {
  name: 'ComponentProjectVisual',
  components: {},
  data: () => ({}),
  props: {
    visual: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .component-project-visual{}
</style>