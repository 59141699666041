<template>
  <section class="section-intro full-screen">
    <div class="grid">
      <div class="row">
        <div class="column width-7 portrait">
          <img :src="require('@/images/'+data.portraitImage)" loading="eager" />
        </div>
        <div class="column width-5 intro">
          <div class="content-wrapper v-align-wrapper">
            <div>
              <p class="size-xl" v-html="data.title"></p>
              <p v-html="data.about"></p>
              <p v-html="data.location"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionIntro',
  components: {},
  data: () => ({}),
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .section-intro{
    background-color: $layout_section-intro_background-color;

    .grid{
      .row{
        min-height: 100vh;
        justify-content: flex-end;
        @include screens-landscape { @include flex-direction(row-reverse); }

        .column{
          position: relative;

          &.portrait{
            @include flex(1); // Make portrait shrink to whatever space is left

            img{
              position: absolute;
              top: 0;
              right: 0;
              width: auto;
              height: 125%;
              @include screens-landscape { height: 100%; }
            }
          }
          
          &.intro{
            @include bevel-top(30px, white);
            background-color: white;
            z-index: 1; // Make sure intro will be sitting on top of portrait image
            @include screens-landscape {
              &:before{ content: none; }
              background-color: transparent;
            }
            @include screens-s-landscape {
              padding-top: $layout_header-elements_height_s; // Make sure intro won't be sitting behind the main menu
              width: 60%; 
            }
            @include screens-m-landscape {
              padding-top: $layout_header-elements_height_m;
              width: 52%; 
            }
            @include screens-l {
              padding-top: $layout_header-elements_height_l;
              width: 52%; 
            }

            .content-wrapper{
              padding-top: $layout_sections-content-wrapper_padding_s * 0.35; // Padding-top is reduced due to bevel
              @include screens-landscape { padding-right: 0; }
              @include screens-s-landscape { padding-top: $layout_sections-content-wrapper_padding_s; }
              @include screens-m-portrait { padding-top: $layout_sections-content-wrapper_padding_m * 0.6; }
              @include screens-m-landscape { padding-top: $layout_sections-content-wrapper_padding_m; }
              @include screens-l { padding-top: $layout_sections-content-wrapper_padding_l; }
            }

            p{
              color: $typo_color-alt;
              @include screens-l { font-size: $layout_section-intro_intro_font-size_l; }

              &.size-xl{
                font-size: $layout_section-intro_intro-xl_font-size_s;
                font-weight: 800;
                margin-left: -0.05em;
                margin-top: -0.25em; // Remove top space above element
                @include screens-m-portrait { font-size: $layout_section-intro_intro-xl_font-size_m-portrait; }
                @include screens-m-landscape { font-size: $layout_section-intro_intro-xl_font-size_m-landscape; }
                @include screens-l-portrait { font-size: $layout_section-intro_intro-xl_font-size_l-portrait; }
                @include screens-l-landscape { font-size: $layout_section-intro_intro-xl_font-size_l-landscape; }
              }
            }
          }
        }
      }
    }
  }
</style>