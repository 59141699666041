<template>
  <section class="section-project-others">
    <div class="content-wrapper">
      <ComponentProjectThumbnailList :projects="this.filteredProjects" :projectIdCurrent="projectIdCurrent" @click-project-thumbnail="clickProjectThumbnail" />
    </div>
  </section>
</template>

<script>
import ComponentProjectThumbnailList from '@/components/_ComponentProjectThumbnailList.vue'

export default {
  name: 'SectionProjectOthers',
  components: {
    ComponentProjectThumbnailList
  },
  data: () => ({}),
  props: {
    projectIdCurrent: {
      type: Number,
      default: null
    },
    projects: {
      type: Array,
      default: null
    }
  },
  computed: {
    filteredProjects() {
      return this.projects.filter((project) => this.projects.indexOf(project) !== this.projectIdCurrent);
    }
  },
  methods: {
    clickProjectThumbnail(payload) {
      this.$emit('click-project-others', payload)
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .section-project-others{
    overflow-x: auto; // Enable horizontal scrolling
    background-color: $layout_section-project-others_background-color;

    .content-wrapper{
      width: 200%;
      padding: calc(#{$layout_sections-content-wrapper_padding_s} - #{$layout_section-projects_thumbnails_gutter}/2);
      @include screens-s-landscape { width: 145%; }
      @include screens-m-portrait { width: 150%; }
      @include screens-m-landscape { width: 115%; }
      @include screens-l-portrait { width: 150%; }
      @include screens-l-landscape { width: 100%; }

      .component-project-thumbnail-list{
        .grid{
          .row{
            @include flex-direction(row);
            flex-wrap: nowrap; // Make sure it will stay on one line

            .column{
              @include flex(1);

              .component-project-thumbnail{
                .details{
                  h3{ 
                    font-size: $layout_section-project-others_thumbnails-title_font-size_s;
                    margin-bottom: 5px;
                    @include screens-m {
                      font-size: $layout_section-project-others_thumbnails-title_font-size_m;
                      margin-bottom: 10px;
                    }
                    @include screens-l {
                      font-size: $layout_section-project-others_thumbnails-title_font-size_l;
                      margin-bottom: 15px;
                    }
                  }
                  p{
                    font-size: $layout_section-project-others_thumbnails-subtitle_font-size_s; 
                    @include screens-m { font-size: $layout_section-project-others_thumbnails-subtitle_font-size_m; }
                    @include screens-l { font-size: $layout_section-project-others_thumbnails-subtitle_font-size_l; }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>