<template>
  <section class="section-testimonials">
    <ComponentCarousel :slides="data.testimonials" />
  </section>
</template>

<script>
import ComponentCarousel from '@/components/_ComponentCarousel.vue'

export default {
  name: 'SectionTestimonials',
  components: {
    ComponentCarousel
  },
  data: () => ({}),
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .section-testimonials{
    background-color: $layout_section-testimonials_background-color;
  }
</style>