<template>
  <div :class="'icon-mouse-scroll ' + teint">
    <span class="wheel"></span>
  </div>
</template>

<script>
export default {
  name: 'IconMouseScroll',
  components: {},
  data: () => ({}),
  props: {
    teint: {
      type: String,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  @keyframes scroll {
    0% {
      @include transform(translate(-50%, 0));
    }
    100% {
      @include transform(translate(-50%, 50%));
    }
  }

  .icon-mouse-scroll{
    display: block;
    width: 20px;
    height: 30px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    border: 1px solid;
    border-radius: 8px 8px 10px 10px;
    @include transform(translateX(-50%));

    .wheel {
      display: inline-block;
      width: 2px;
      height: 5px;
      position: absolute;
      top: 5px;
      left: 50%;
      border-radius: 5px;
      background: black;
      @include animation(750ms ease-in-out infinite alternate scroll);
    }

    &:after{
      content: "scroll";
      position: absolute;
      bottom: -15px;
      left: 50%;
      font-family: 'Raleway', sans-serif;
      font-size: 10px;
      color: black;
      @include transform(translateX(-50%));
    }

    &.dark{
      border-color: $layout_icon-mouse-scroll_color-dark;
      .wheel{ background: $layout_icon-mouse-scroll_color-dark; }
      &:after{ color: $layout_icon-mouse-scroll_color-dark; }
    }

    &.light{
      border-color: $layout_icon-mouse-scroll_color-light;
      .wheel{ background: $layout_icon-mouse-scroll_color-light; }
      &:after{ color: $layout_icon-mouse-scroll_color-light; }
    }
  }
</style>