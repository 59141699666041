<template>
  <div class="component-project-brand-guidelines" :style="{'background-color': brandGuidelines.backgroundColor}">
    <div class="grid">
      <div class="row">
        <div class="column width-6">
          <div class="grid">
            <div class="row">
              <div v-for="(color, index) in brandGuidelines.colors" :key="index" :class="'column background-' +  color.teint" :style="{'background-color': color.hexadecimalCode}">
                <p class="vertical-caption left">{{ color.hexadecimalCode }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column width-6">
          <div v-for="(element, index) in brandGuidelines.elements" :key="index" :class="element.type" >
            <h5>{{ element.title }}</h5>
            <!-- Logos -->
            <div v-if="element.type === 'logos'" class="grid">
              <div class="row with-gutter">
                <div v-for="(logo, index) in element.logos" :key="index" class="column width-6">
                  <div class="logo-wrapper" :style="{'background-color': logo.backgroundColor}">
                    <img :src="require('@/images/projects/'+logo.imageFile)" :alt="logo.imageAlt" />
                  </div>
                </div>
              </div>
            </div>
            <!-- Visual -->
            <img v-if="element.type === 'visual'" :src="require('@/images/projects/'+element.imageFile)" :alt="element.title" />
          </div>
        </div>
      </div>
    </div>
    <p :class="'vertical-caption ' + brandGuidelines.captionPosition">{{ brandGuidelines.caption }}</p>
  </div>
</template>

<script>
export default {
  name: 'ComponentProjectBrandGuidelines',
  components: {},
  data: () => ({}),
  props: {
    brandGuidelines: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .component-project-brand-guidelines{
    & > .grid{
      & > .row{
        & > .column{
          &:nth-child(1){
            & > .grid{
              height: inherit;
              @include screens-s-landscape{ height: 100%; }
              @include screens-m-portrait{ height: inherit; }
              @include screens-m-landscape{ height: 100%; }
              @include screens-l-portrait{ height: inherit; }
              @include screens-l-landscape{ height: 100%; }

              & > .row{
                height: 250px;
                @include flex-direction(row);
                @include screens-s-landscape{ height: 100%; }
                @include screens-m-portrait{ height: 350px; }
                @include screens-m-landscape{ height: 100%; }
                @include screens-l-portrait{ height: 350px; }
                @include screens-l-landscape{ height: 100%; }

                & > .column{
                  position: relative;
                  @include transition(all 350ms);

                  .vertical-caption{
                    bottom: 11px;
                    left: 22px;
                    @include transition(all 350ms);
                  }

                  &:hover{
                    @include flex(2); // Make the width twice as big as default on hover

                    .vertical-caption{
                      bottom: 7px;
                      font-size: $typo_caption_font-size_s * 1.35;
                      @include screens-m{ font-size: $typo_caption_font-size_m * 1.35; }
                      @include screens-l{ font-size: $typo_caption_font-size_l * 1.35; }
                    }
                  }

                  &.background-light{
                    p{ color: #3B3B3B; }
                  }
                  &.background-dark{ 
                    p{ color: #FFFFFF; }
                  }
                }
              }
            }
          }

          &:nth-child(2){
            padding: 20px;
            @include screens-m { padding: 35px; }
            @include screens-l { padding: 45px; }

            h5{
              color: #ABABAB;
            }

            & > * {
              padding-top: $layout_columns_gutter_s * (1/4);
              padding-bottom: $layout_columns_gutter_s * (1/4);
              @include screens-m{ 
                padding-top: $layout_columns_gutter_m * (1/4);
                padding-bottom: $layout_columns_gutter_m * (1/4);
              }
              @include screens-l{
                padding-top: $layout_columns_gutter_l * (1/4);
                padding-bottom: $layout_columns_gutter_l * (1/4);
              }

              &:first-of-type{
                padding-top: 0;
              }
              &:last-of-type{
                padding-bottom: 0;
              }

              &.logos{
                .grid{
                  .row{
                    @include flex-direction(row);

                    .column{
                      width: 50%;

                      &:nth-child(2n+1){
                        padding-right: 10px;
                        @include screens-m { padding-right: 17.5px; }
                        @include screens-l { padding-right: 22.5px; }
                      }
                      &:nth-child(2n+2){
                        padding-left: 10px;
                        @include screens-m { padding-left: 17.5px; }
                        @include screens-l { padding-left: 22.5px; }
                      }

                      .logo-wrapper{
                        padding: 15px;
                        @include screens-m{ padding: 20px; }
                        @include screens-l{ padding: 30px; }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>