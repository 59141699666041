import { render, staticRenderFns } from "./__IconLogo.vue?vue&type=template&id=f35188c0&"
import script from "./__IconLogo.vue?vue&type=script&lang=js&"
export * from "./__IconLogo.vue?vue&type=script&lang=js&"
import style0 from "./__IconLogo.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports