<template>
  <div class="view" id="view-home">
    <!-- Main -->
    <div id="main">
      <!-- Header -->
      <ComponentLogo />
      <ComponentMenu :data="$t('componentMenu')" @scroll-to="scrollToPayload" />
      <ComponentButtonIcon icon="IconHamburger" @click-button-icon="openPopup('menu')" />
      <!-- Sections -->
      <SectionIntro :data="$t('sectionIntroData')" ref="section-intro" />
      <SectionSkills :data="$t('sectionSkillsData')" ref="section-skills" />
      <SectionProjects :data="$t('sectionProjectsData')" ref="section-projects" @click-project-thumbnail="openPopupPayload" />
      <SectionTestimonials :data="$t('sectionTestimonialsData')" ref="section-testimonials" />
      <SectionClients :data="$t('sectionClientsData')" ref="section-clients" />
      <SectionContact :data="$t('sectionContactData')" ref="section-contact" />
    </div>
    <!-- Popups -->
    <transition name="fade">
      <PopupMenu v-if="currentPopup.type === 'menu'" @scroll-to="scrollToPayload" @click-button-icon="closePopup()" ref="popupMenu" />
      <PopupProjects v-if="currentPopup.type === 'projects'" :projectId="currentPopup.ref" @scroll-to="scrollToPayload" @click-project-others="openPopupPayload" @click-button-icon="closePopup()" ref="popupProjects" />
    </transition>
  </div>
</template>

<script>
import ComponentLogo from '@/components/_ComponentLogo.vue'
import ComponentMenu from '@/components/_ComponentMenu.vue'
import SectionIntro from '@/components/SectionIntro.vue'
import SectionSkills from '@/components/SectionSkills.vue'
import SectionProjects from '@/components/SectionProjects.vue'
import SectionTestimonials from '@/components/SectionTestimonials.vue'
import SectionClients from '@/components/SectionClients.vue'
import SectionContact from '@/components/SectionContact.vue'
import PopupMenu from '@/components/PopupMenu.vue'
import PopupProjects from '@/components/PopupProjects.vue'
import ComponentButtonIcon from '@/components/_ComponentButtonIcon.vue'

export default {
  name: 'Home',
  components: {
    ComponentLogo,
    ComponentMenu,
    SectionIntro,
    SectionSkills,
    SectionProjects,
    SectionTestimonials,
    SectionClients,
    SectionContact,
    PopupMenu,
    PopupProjects,
    ComponentButtonIcon
  },
  data: () => ({
    currentPopup: {
      type: null,
      ref: null
    }
  }),
  methods: {
    openPopup(type, ref = null){
      this.currentPopup.type = type;
      this.currentPopup.ref = ref;
      // Disable scrolling in #main
      document.body.classList.add('disable-scrolling');
      // Scroll to top
      setTimeout(function() {
        var popup = document.getElementById('popup');
        popup.scrollTo({
          top: 0, 
          behavior: 'smooth' 
        })
      }, 250);
    },
    openPopupPayload(payload){
      this.openPopup(payload.type, payload.ref);
    },
    closePopup(){
      this.currentPopup.type = null;
      this.currentPopup.ref = null;
      // Re-enable scrolling
      document.body.classList.remove('disable-scrolling');
    },
    scrollToPayload(payload){
      this.scrollTo(payload.ref, payload.delay);
    },
    scrollTo(ref, delay=0, scrollSpeedThousandPixels=250, scrollStepsTotal=100){
      var anchor = this.$refs[ref].$el;
      setTimeout(function() { // Wait for delay before start scrolling
        // Had to create a custom JS function as I wanted a smooth scroll effect 
        // and "scroll-behavior" was unfortunately not supported on Safari ("scrollTo" function option)
        var stepIndex = 0,
            remainingScrollAmountToAnchor = anchor.offsetTop - window.scrollY,
            scrollSpeed = Math.ceil(remainingScrollAmountToAnchor/1000 * scrollSpeedThousandPixels);
        
        for(var i=0; i<scrollStepsTotal; i++){
          setTimeout(function() {
            var scrollStepToAnchor = Math.ceil(remainingScrollAmountToAnchor/(scrollStepsTotal-stepIndex));
            window.scrollBy(0, scrollStepToAnchor);
            remainingScrollAmountToAnchor -= scrollStepToAnchor;
            stepIndex++;
          }, (i+1)*(scrollSpeed/scrollStepsTotal));
        }
      }, delay);
    } 
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";
  
  .view{
    #main{
      .component-button-icon{
        @include screens-m-landscape { display: none; }
        @include screens-l-landscape { display: none; }
      }
    }
  }
</style>
