<template>
  <svg version="1.1" class="icon-envelop" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <path d="M50.1,57l-0.1,0.1l-0.1,0l-0.1,0L49.9,57L5.3,33.6v42.6c0,5.3,4.3,9.6,9.6,9.6h70.2c5.3,0,9.6-4.3,9.6-9.6V33.6L50.1,57z"/>
    <path d="M50,49.4l44.7-23.5v-2.1c0-5.3-4.3-9.6-9.6-9.6H14.9c-5.3,0-9.6,4.3-9.6,9.6v2.1L50,49.4z"/>
  </svg>
</template>

<script>
export default {
  name: 'IconEnvelop',
  components: {},
  data: () => ({}),
  props: {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .icon-envelop{}
</style>