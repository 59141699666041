<template>
  <div class="component-menu">
    <div class="grid">
      <ul class="row">
        <li v-for="(section, index) in data.sections" :key="index" :class="'column link link-'+section.anchor" @click="scrollTo('section-'+ section.anchor)">
          <div class="v-align-wrapper">
            <span>{{ section.title }}</span>
          </div>
        </li>
        <li class="column language-switch">
          <div class="v-align-wrapper">
            <div>
              <span v-for="(lang, i) in langs" :key="`lang-${i}`" :class="lang === $i18n.locale ? 'active' : ''" @click="setLocale(lang)">{{ lang.toUpperCase() }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentMenu',
  components: {},
  data: () => ({
    langs: ['fr', 'en']
  }),
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {
    scrollTo(ref) {
      this.$emit('scroll-to', { ref: ref, delay: 0 })
    },
    setLocale(locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .component-menu{
    position: absolute;
    top: 0;
    left: $layout_header-elements_width_s;
    z-index: 2;
    display: none; // Hidden by default on mobile
    @include screens-m-landscape {
      display: block;
      left: $layout_header-elements_width_m; 
    }
    @include screens-l-landscape {
      display: block;
      left: $layout_header-elements_width_l; 
    }

    .grid{
      .row{
        @include flex-direction(row);
        flex-wrap: wrap; // Make sure it will stay in one line

        .column{
          width: $layout_header-elements_width_s;
          height: $layout_header-elements_height_s;
          padding: $layout_header-elements_padding_s;
          text-align: center;
          @include screens-m { 
            width: $layout_header-elements_width_m;
            height: $layout_header-elements_height_m;
            padding: $layout_header-elements_padding_m;
          }
          @include screens-l { 
            width: $layout_header-elements_width_l;
            height: $layout_header-elements_height_l;
            padding: $layout_header-elements_padding_l;
          }

          &.logo { background-color: black; }
          &.link-skills { background-color: $layout_colours-blue-pastel; }
          &.link-projects { background-color: $layout_colours-green-pastel; }
          &.link-testimonials { background-color: $layout_colours-yellow-pastel; }
          &.link-clients { background-color: $layout_colours-orange-pastel; }
          &.link-contact { background-color: $layout_colours-purple-pastel; }
          &.language-switch { background-color: white; }

          span{
            font-size: $layout_header-elements_font-size_s;
            font-weight: 300;
            color: $typo_color-alt;
            @include screens-m { font-size: $layout_header-elements_font-size_m; }
            @include screens-l { font-size: $layout_header-elements_font-size_l; }

            // Add " / " between each language options
            &:not(:last-of-type):after{
              content: " / ";
              font-weight: 300;
            }

            // Make sur the active element is bold and the non active ones are cliquable
            &.active{
              font-weight: $typo_strong_font-weight;
            }
            &:not(.active):hover{
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>