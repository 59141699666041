<template>
  <div class="component-button-icon button" @click="clickButtonIcon">
    <component :is="icon" />
  </div>
</template>

<script>
import IconHamburger from '@/components/__IconHamburger.vue'
import IconCross from '@/components/__IconCross.vue'

export default {
  name: 'ComponentButtonIcon',
  components: {
    IconHamburger,
    IconCross
  },
  data: () => ({}),
  props: {
    icon: {
      type: String,
      default: null
    }
  },
  computed: {},
  methods: {
    clickButtonIcon() {
      this.$emit('click-button-icon')
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .component-button-icon{
    position: absolute;
    top: 0;
    right: 0;
    width: $layout_header-elements_height_s;
    height: $layout_header-elements_height_s;
    padding: $layout_header-elements_padding_s;
    @include screens-m { padding: $layout_header-elements_padding_m; }
    @include screens-l { padding: $layout_header-elements_padding_l; }
    background-color: white;
  }
</style>