<template>
  <section class="section-contact">
    <div class="content-wrapper vertical-align-wrapper">
      <div class="grid">
        <div class="row">
          <div class="column width-4 availability">
            <h3 v-html="data.availability.title"></h3>
            <p v-html="data.availability.details"></p>
          </div>
          <div class="column width-4 price">
            <h3 v-html="data.price.title"></h3>
            <p v-html="data.price.details"></p>
          </div>
          <div class="column width-4 contact">
            <h3 v-html="data.contact.title"></h3>
            <div class="options">
              <a v-if="data.contact.mailLink" target='_blank' :href="data.contact.mailLink">
                <IconEnvelop />
              </a>
              <a v-if="data.contact.maltLink" target='_blank' :href="data.contact.maltLink">
                <IconMalt />
              </a>
              <a v-if="data.contact.linkedinLink" target='_blank' :href="data.contact.linkedinLink">
                <IconLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="decorative-line"></div>
  </section>
</template>

<script>
import IconEnvelop from '@/components/__IconEnvelop.vue'
import IconMalt from '@/components/__IconMalt.vue'
import IconLinkedin from '@/components/__IconLinkedin.vue'

export default {
  name: 'sectionContact',
  components: {
    IconEnvelop,
    IconMalt,
    IconLinkedin
  },
  data: () => ({}),
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .section-contact{
    text-align: center;
    background-color: $layout_colours-grey-extra-dark;

    .content-wrapper{
      padding: calc(#{$layout_sections-content-wrapper_padding_s} * 1.75);
      @include screens-s-landscape { 
        padding-left: $layout_sections-content-wrapper_padding_s;
        padding-right: $layout_sections-content-wrapper_padding_s;
      }
      @include screens-m { padding: calc(#{$layout_sections-content-wrapper_padding_m} * 1.5); }
      @include screens-m-landscape { 
        padding-left: $layout_sections-content-wrapper_padding_m;
        padding-right: $layout_sections-content-wrapper_padding_m;
      }
      @include screens-l { padding: #{$layout_sections-content-wrapper_padding_l} }

      h3{
        font-size: $layout_section-footer_title_s;
        margin-bottom: 10px;
        @include screens-m {
          margin-bottom: 11px;
          font-size: $layout_section-footer_title_m; 
        }
        @include screens-l {
          margin-bottom: 12px;
          font-size: $layout_section-footer_title_l; 
        }
      }

      .grid{
        .row > *{
          margin: calc(#{$layout_sections-content-wrapper_padding_s} * 0.75) 0;
          @include screens-s-landscape {
            padding-left: calc(#{$layout_sections-content-wrapper_padding_s} * 0.5);
            padding-right: calc(#{$layout_sections-content-wrapper_padding_s} * 0.5);
          }
          @include screens-m-portrait {
            margin: calc(#{$layout_sections-content-wrapper_padding_m} * 0.5) 0;
          }
          @include screens-m-landscape {
            padding-left: calc(#{$layout_sections-content-wrapper_padding_m} * 0.5);
            padding-right: calc(#{$layout_sections-content-wrapper_padding_m} * 0.5);
          }
          @include screens-l-portrait {
            margin: calc(#{$layout_sections-content-wrapper_padding_l} * 0.35) 0;
          }
          @include screens-l-landscape {
            margin: 0;
            padding-left: calc(#{$layout_sections-content-wrapper_padding_l} * 0.5);
            padding-right: calc(#{$layout_sections-content-wrapper_padding_l} * 0.5);
          }

          &.contact{
            .options > *{
              display: inline-block;
              margin: 0 7px;
              
              svg{
                width: $layout_section-footer_icons-width;
                fill: white;
              }
            }
          }
        }
      }
    }

    .decorative-line{ 
      width: 100%;
      height: $layout_section-footer_decorative-line_height_s;
      background: $layout_colours-gradient-vivid;
      @include screens-m { height: $layout_section-footer_decorative-line_height_m; }
      @include screens-l { height: $layout_section-footer_decorative-line_height_l; }
    }
  }
</style>