<template>
  <div id="popup" class="popup-projects">
    <SectionProjectHero :project="$t('sectionProjectsData').projects[projectId]" />
    <SectionProjectDetails :project="$t('sectionProjectsData').projects[projectId]" />
    <SectionProjectGallery :project="$t('sectionProjectsData').projects[projectId]" />
    <SectionProjectOthers :projectIdCurrent="projectId" :projects="$t('sectionProjectsData').projects" @click-project-others="clickProjectOthers" />
    <ComponentButtonIcon icon="IconCross" @click-button-icon="clickButtonIcon"  />
  </div>
</template>

<script>
import SectionProjectHero from '@/components/SectionProjectHero.vue'
import SectionProjectDetails from '@/components/SectionProjectDetails.vue'
import SectionProjectGallery from '@/components/SectionProjectGallery.vue'
import SectionProjectOthers from '@/components/SectionProjectOthers.vue'
import ComponentButtonIcon from '@/components/_ComponentButtonIcon.vue'

export default {
  name: 'PopupProjects',
  components: {
    SectionProjectHero,
    SectionProjectDetails,
    SectionProjectGallery,
    SectionProjectOthers,
    ComponentButtonIcon
  },
  data: () => ({}),
  props: {
    projectId: {
      type: Number,
      default: null
    }
  },
  computed: {},
  methods: {
    clickButtonIcon() {
      this.$emit('click-button-icon')
    },
    clickProjectOthers(payload) {
      this.$emit('click-project-others', payload)
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/_mixins.scss";
  @import "@/scss/_variables.scss";

  .popup-projects{}
</style>